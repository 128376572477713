export enum BookFlightTab {
  Return = "Return",
  RoundTrip = "Round Trip",
  OneWay = "One-way",
  MultiCity = "Multi-city",
}

export enum BookFlightAirportDropdown {
  From = "from",
  To = "to",
}

export enum PassengerDetails {
  Adult = "adult",
  Youth = "youth",
  Child = "child",
  Infant = "infant",
}

export enum CabinClass {
  Economy = "PE",
  Business = "FB",
}

export enum BookFlightTabSections {
  RoundTrip = "Round Trip",
  OneWay = "One Way",
  Return = "Return",
}

export enum BookFlightMainTabSections {
  BookFlight = "Book Flight",
  ManageBooking = "Manage Booking",
  CheckIn = "Check-In",
  FlightStatus = "Flight Status",
  MoreServices = "More",
}

export enum FlightStatusType {
  FlightNumber = "Flight Number",
  Route = "Route",
}
export enum FlightScheduleAirportDropdown {
  From = "from",
  To = "to",
}

export enum FlightScheduleTab {
  Return = "Return",
  OneWay = "OneWay",
}
